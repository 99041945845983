<template>
  <mf-loading-dialog :loading="$apollo.loading || loading">
    <base-page-layout :title="$route.meta.title" :showHeader="isCreate">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <label class="card-title">CNPJ</label>
              <mf-text-input v-model="franchise.cnpj" v-mask="'##.###.###/####-##'" outlined :errors-messages="errorMessages.cnpj" />
            </v-col>
            <v-col cols="3">
              <label class="card-title">Razão Social</label>
              <mf-text-input v-model="franchise.corporate_name" outlined :errors-messages="errorMessages.corporate_name" />
            </v-col>
            <v-col cols="3">
              <label class="card-title">Nome Fantasia</label>
              <mf-text-input v-model="franchise.fantasy_name" outlined :errors-messages="errorMessages.fantasy_name" />
            </v-col>
            <v-col cols="3">
              <label class="card-title">Data de criação</label>
              <date-picker
                :date.sync="franchise.created_at"
                solo-inverted
                outline
                :error-messages="(getSafe(() => $v.loyaltyProgramStartDate.$error) && 'Insira uma data de início de clube.') || ''"
              />
            </v-col>
          </v-row>

          <h2>Endereço:</h2>
          <v-row>
            <v-col cols="2">
              <label class="card-title">CEP</label>
              <mf-text-input v-model="internalPostalCode" v-mask="'#####-###'" :loading="loadingCep" :errors-messages="errorMessages.postal_code" outlined />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Estado</label>
              <v-select
                v-model="franchise.address.federative_unit"
                label="Estado"
                :items="estados"
                item-text="name"
                item-value="value"
                :error-messages="errorMessages.federative_unit"
                outlined
                single-line
              />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Cidade</label>
              <v-select
                v-model="franchise.address.city_name"
                label="Cidade"
                :disabled="franchise.address.federative_unit === ''"
                :items="cidadesFiltradas"
                item-text="Nome"
                item-value="Nome"
                :error-messages="errorMessages.city_name"
                outlined
                single-line
              />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Bairro</label>
              <mf-text-input v-model="franchise.address.neighborhood" :errors-messages="errorMessages.neighborhood" outlined />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Rua</label>
              <mf-text-input v-model="franchise.address.street" :errors-messages="errorMessages.street" outlined />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Número</label>
              <mf-text-input v-model="franchise.address.number" :errors-messages="errorMessages.number" outlined />
            </v-col>
            <v-col cols="12">
              <label class="card-title">Endereço completo</label>
              <mf-text-input v-model="franchise.address.complement" :errors-messages="errorMessages.complement" outlined />
            </v-col>
            <v-spacer />
          </v-row>

          <h2>Contatos:</h2>
          <contact-form :contacts.sync="franchise.contacts" :validations="$v.franchise" />
        </v-card-text>
      </v-card>
      <mf-action-buttons
        class="mt-2"
        :primary-button="{
          text: isCreate ? 'Criar rede' : 'Editar rede',
          action: isCreate ? create : saveFranchise,
          isVisible: true,
          isDisabled: false,
          isLoading: loadingSaveActive
        }"
        :cancel-button="{
          text: 'Cancelar',
          action: () => $router.push('/franchises'),
          isVisible: true,
          isDisabled: false
        }"
      />
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

import { deepDelete } from '@/helpers/deepDelete'
import { getDataFromViaCep } from '@/helpers/cep'
import { validCnpj, notOnlySpace } from '@/helpers/validators.js'
import { MUTATION_CREATE_FRANCHISE, MUTATION_UPDATE_FRANCHISE } from '@/modules/franchises/graphql'
import { data as cidades } from '@/assets/data/cidades.json'
import { data as estados } from '@/assets/data/estados.json'
import { QUERY_SEARCH_CNPJ } from '@/modules/retailers/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    ContactForm: () => import('@/components/form/ContactForm.vue'),
    DatePicker: () => import('@/components/calendar/DatePicker.vue')
  },
  data() {
    return {
      franchise: {
        address: {
          postal_code: '',
          federative_unit: '',
          city_name: '',
          neighborhood: '',
          street: '',
          complement: '',
          number: ''
        },
        contacts: [{}],
        created_at: this.$moment().format('YYYY-MM-DD')
      },
      validateCnpjTimeout: false,
      validatingCnpj: false,
      loading: false,
      cidades: cidades,
      estados: estados,
      loadingCep: false,
      loadingSaveActive: false,
      postalCodeTouched: false,
      foundCnpj: []
    }
  },
  computed: {
    internalPostalCode: {
      get() {
        return this.franchise.address.postal_code
      },
      set(value) {
        const newRaw = value.replace(/[^\d]+/g, '')
        const oldRaw = this.franchise.address.postal_code.replace(/[^\d]+/g, '')
        if (newRaw !== oldRaw) {
          this.postalCodeTouched = true
        }
        this.franchise.address.postal_code = value
      }
    },
    defaultFullAddress() {
      const fullAddressComponents = []
      if (this.franchise.address.street) {
        fullAddressComponents.push(this.franchise.address.street)
      }

      if (this.franchise.address.number || this.franchise.address.neighborhood) {
        fullAddressComponents.push([this.franchise.address.number, this.franchise.address.neighborhood].filter(el => Boolean(el)).join(' - '))
      }

      if (this.franchise.address.city_name || this.franchise.address.federative_unit) {
        fullAddressComponents.push([this.franchise.address.city_name, this.franchise.address.federative_unit].filter(el => Boolean(el)).join(' - '))
      }

      if (this.franchise.address.postal_code) {
        fullAddressComponents.push(this.franchise.address.postal_code)
      }
      return fullAddressComponents.join(', ')
    },
    cidadesFiltradas() {
      return this.cidades.filter(cidade => cidade.Uf === this.franchise.address.federative_unit)
    },
    errorMessages() {
      const fields = ['cnpj', 'fantasy_name', 'corporate_name']

      const errors = fields.reduce((errors, field) => {
        if (this.$v.franchise[field] && this.$v.franchise[field].$error) {
          if (!this.$v.franchise[field].required) errors[field] = 'Campo obrigatório.'
          else if (!this.$v.franchise[field].notOnlySpace) errors[field] = 'Insira um CNPJ válido.'
        } else {
          errors[field] = ''
        }
        return errors
      }, {})
      if (this.$v.franchise.address.complement.$error) errors['complement'] = 'Endereço completo inválido.'
      if (this.$v.franchise.address.postal_code.$error) errors['postal_code'] = 'CEP inválido.'
      if (this.$v.franchise.address.federative_unit.$error) errors['federative_unit'] = 'Estado inválido.'
      if (this.$v.franchise.address.city_name.$error && !this.$v.franchise.address.federative_unit.$error) errors['city_name'] = 'Cidade inválida.'
      if (this.$v.franchise.address.neighborhood.$error) errors['neighborhood'] = 'Bairro inválido.'
      if (this.$v.franchise.address.street.$error) errors['street'] = 'Rua inválida.'
      if (this.$v.franchise.address.number.$error) errors['number'] = 'Número inválido.'
      if (!errors.cnpj && !this.$v.franchise.cnpj.validCnpj) errors.cnpj = 'CNPJ inválido.'
      if (!errors.cnpj && this.foundCnpj.length) errors.cnpj = 'CNPJ já cadastrado.'
      return errors
    },
    isCreate() {
      return this.$route.meta.title === 'Criar rede'
    }
  },
  watch: {
    async 'franchise.address.postal_code'(value) {
      const raw = value.replace(/[^\d]+/g, '')
      if (raw.length !== 8 || !this.postalCodeTouched) {
        return
      }
      try {
        this.loadingCep = true
        const cepData = await getDataFromViaCep(raw)
        this.franchise.address.federative_unit = cepData.uf || ''
        this.franchise.address.city_name = cepData.localidade || ''
        this.franchise.address.neighborhood = cepData.bairro || ''
        this.franchise.address.street = cepData.logradouro || ''
        this.franchise.address.complement = this.defaultFullAddress
      } catch (e) {
        this.$snackbar({ message: 'Falha ao procurar o CEP' })
      }
      this.loadingCep = false
    },
    'franchise.cnpj'(value) {
      const search = value?.replace(/[^0-9]/g, '')
      if (search.length === 14) {
        clearTimeout(this.validateCnpjTimeout)
        this.validateCnpjTimeout = setTimeout(() => {
          this.validateCnpj(search)
        }, 200)
      }
    }
  },
  mounted() {
    if (!this.isCreate) this.franchise = { ...this.$attrs.franchise }
  },
  methods: {
    cancelAndRestore() {
      this.franchise = { ...this.$attrs.franchise }
    },
    validateForm() {
      this.$v.$reset()
      this.$v.$touch()
      if (this.$v.$error) {
        this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: 'error' })
        this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
        return false
      }
      return true
    },
    formatForm() {
      this.franchise = {
        ...this.franchise,
        cnpj: this.franchise.cnpj.replace(/[^0-9]/g, '')
      }
    },
    async saveFranchise() {
      const isValidated = this.validateForm()
      if (!isValidated) return
      this.formatForm()

      deepDelete(this.franchise, ['_id', 'active', 'revenue', '__typename'])
      try {
        this.loadingSaveActive = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_FRANCHISE,
          variables: {
            franchise: this.franchise,
            franchise_id: this.$attrs.franchise._id
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['crm']
          }
        })
        setTimeout(() => {
          this.$snackbar({ message: 'Rede editado com sucesso', snackbarColor: 'success' })
        }, 300)
        this.$router.push(`/franchises`)
      } catch (error) {
        this.$snackbar({ message: 'Falha ao editar rede', snackbarColor: 'error' })
      } finally {
        this.loadingSaveActive = false
      }
    },
    async create() {
      const isValidated = this.validateForm()
      if (!isValidated) return
      if (this.validateClientCnpj()) return this.$snackbar({ message: 'CNPJ inválido ou já cadastrado em nossa base', snackbarColor: 'error' })
      this.formatForm()

      this.loadingSaveActive = true

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CREATE_FRANCHISE,
          variables: {
            franchise: this.franchise
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['crm']
          }
        })

        this.loadingSaveActive = false

        setTimeout(() => {
          this.$snackbar({ message: 'Rede criado com sucesso', snackbarColor: 'success' })
        }, 300)

        this.$router.push(`/franchises`)
      } catch (e) {
        console.log(e.message)
        this.$snackbar({ message: 'Falha ao criar rede', snackbarColor: 'error' })
      }
      this.loadingSaveActive = false
    },
    /**
     * Busca o cnpj pelo valor inserido para validar duplicados
     */
    async validateCnpj(search) {
      this.validatingCnpj = true
      try {
        const res = await this.$apollo.query({
          query: QUERY_SEARCH_CNPJ,
          fetchPolicy: 'no-cache',
          variables: { search },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })

        this.foundCnpj = res?.data?.searchCnpj?.filter(item => item._id !== this.$attrs.franchise?._id)?.map(item => item.cnpj)
      } catch (e) {
        console.warn('Failed to load cnpj', e)
      }
      this.validatingCnpj = false
    },
    validateClientCnpj() {
      return this.foundCnpj.length > 0
    }
  },
  validations: {
    franchise: {
      cnpj: { required, validCnpj },
      fantasy_name: { required, notOnlySpace },
      corporate_name: { required, notOnlySpace },
      address: {
        postal_code: { required, notOnlySpace, minLength: minLength(9), maxLength: maxLength(9) },
        federative_unit: { required, notOnlySpace },
        city_name: { required, notOnlySpace },
        neighborhood: { required, notOnlySpace },
        street: { required, notOnlySpace },
        number: { required, notOnlySpace },
        complement: { required, notOnlySpace }
      },
      contacts: {
        required,
        $each: {
          owner: { required },
          role: { required },
          email: { required, email },
          phone: { required, notOnlySpace, minLength: minLength(18), maxLength: maxLength(19) }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.btn-focus-action {
  height: min-content;
  display: flex;
  align-self: center;
}
</style>
