import gql from 'graphql-tag'

export const QUERY_GET_FRANCHISES_PAGINATED = gql`
  query franchises($pagination: PaginationParams!) {
    franchises(pagination: $pagination) {
      data {
        _id
        cnpj
        contacts {
          owner
          role
          email
          phone
        }
        corporate_name
        fantasy_name
        total_franchisees
        created_at
        active
        history {
          billed_client
          cause
          cause_details
          details
          inactivated_at
          inactivated_by
          mode
          phase
          reason
          recovered_at
          recovered_by
        }
      }
      count
    }
  }
`

export const QUERY_GET_AVAILABLE_FRANCHISES = gql`
  query availableFranchises {
    availableFranchises {
      _id
      fantasy_name
    }
  }
`

export const QUERY_GET_FRANCHISE = gql`
  query franchise($franchise_id: String!) {
    franchise(franchise_id: $franchise_id) {
      _id
      address {
        city_name
        complement
        federative_unit
        neighborhood
        number
        postal_code
        street
      }
      active
      cnpj
      contacts {
        owner
        role
        email
        phone
      }
      corporate_name
      fantasy_name
      created_at
    }
  }
`

export const MUTATION_CREATE_FRANCHISE = gql`
  mutation registerFranchise($franchise: FranchiseInput!) {
    registerFranchise(franchise: $franchise) {
      message
      success
    }
  }
`

export const MUTATION_UPDATE_FRANCHISE = gql`
  mutation updateFranchise($franchise_id: String!, $franchise: FranchiseUpdate!) {
    updateFranchise(franchise_id: $franchise_id, update: $franchise) {
      message
      success
    }
  }
`

export const MUTATION_CREATE_NEW_SALE = gql`
  mutation registerFranchiseNewSale($franchise_id: String!, $sale: FranchiseSaleInput!) {
    registerFranchiseNewSale(franchise_id: $franchise_id, sale: $sale) {
      message
      success
    }
  }
`
export const MUTATION_CREATE_CROSS = gql`
  mutation registerFranchiseCrossell($franchise_id: String!, $sale: FranchiseSaleInput!) {
    registerFranchiseCrossell(franchise_id: $franchise_id, sale: $sale) {
      message
      success
    }
  }
`
export const MUTATION_CREATE_UPSELL = gql`
  mutation registerFranchiseUpsell($franchise_id: String!, $sale: FranchiseSaleInput!) {
    registerFranchiseUpsell(franchise_id: $franchise_id, sale: $sale) {
      message
      success
    }
  }
`

export const MUTATION_CREATE_DOWNSELL = gql`
  mutation registerFranchiseDownsell($franchise_id: String!, $sale: FranchiseSaleDownsellInput!) {
    registerFranchiseDownsell(franchise_id: $franchise_id, sale: $sale) {
      message
      success
    }
  }
`

export const MUTATION_CREATE_TEMPORARY_CHURN = gql`
  mutation registerFranchiseTemporaryChurn($franchise_id: String!, $sale: FranchiseInsertTemporaryChurnInput!) {
    registerFranchiseTemporaryChurn(franchise_id: $franchise_id, sale: $sale) {
      message
      success
    }
  }
`

export const MUTATION_CREATE_RETURN_TEMPORARY_CHURN = gql`
  mutation registerFranchiseReturnChurn($franchise_id: String!, $sale: FranchiseReturnChurnInput!) {
    registerFranchiseReturnChurn(franchise_id: $franchise_id, sale: $sale) {
      message
      success
    }
  }
`

export const MUTATION_CREATE_RENEGOTIATION_MRR = gql`
  mutation registerRenegotiationMrrFranchise($franchise_id: String!, $sale: FranchiseRenegotiationMrrInput!) {
    registerRenegotiationMrrFranchise(franchise_id: $franchise_id, sale: $sale) {
      message
      success
    }
  }
`

export const MUTATION_SET_CHURN = gql`
  mutation setFranchiseChurn($franchise_id: String!, $churn_options: FranchiseChurnInput!) {
    setFranchiseChurn(franchise_id: $franchise_id, churn_options: $churn_options) {
      message
      success
    }
  }
`

export const MUTATION_UNSET_CHURN = gql`
  mutation unsetFranchiseChurn($franchise_id: String!) {
    unsetFranchiseChurn(franchise_id: $franchise_id) {
      message
      success
    }
  }
`

export const QUERY_GET_FRANCHISE_SALES = gql`
  query franchiseSales($franchise_id: String!, $filters: FranchiseSaleSearchFilter, $pagination: PaginationParams!) {
    franchiseSales(filters: $filters, franchise_id: $franchise_id, pagination: $pagination) {
      count
      data {
        _id
        is_ambev_participation
        ambev_incentive {
          ambev_incentive_months
          ambev_incentive_text
          is_ambev_incentive
        }
        contract_link
        origin
        sub_origin
        created_at
        franchise_id
        items {
          app_type
          billing_date_periods
          charge_type
          immediate_billing
          implantation_value
          index
          is_accumulative
          mrr_renegotiation
          name
          plan
          quantity
          sale_id
          total_value
          type
          unit_value
        }
        observation
        owner
        temporary_churn_period {
          end_date
          start_date
        }
        temporary_churn_reverted
        type
      }
    }
  }
`

export const QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT = gql`
  query franchiseAvailableProducts($franchise_id: String!, $filters: FranchiseAvailableProductFilters, $pagination: PaginationParams!) {
    franchiseAvailableProducts(filters: $filters, franchise_id: $franchise_id, pagination: $pagination) {
      count
      data {
        _id
        product_type
        unit_value
        total_mrr
        slots
        active
        app_type
        created_at
        franchise_id
        product_type
        sale_date
        updated_at
        sale_id
        unit_value
        billing_date
        index
        temporary_churn {
          aboned_quantity
          aboned_value
          temporary_churn_period {
            end_date
            start_date
          }
          temporary_churn_sale_id
        }
      }
    }
  }
`
export const QUERY_GET_FRANCHISE_SALE_BY_ID = gql`
  query franchiseSaleById($sale_id: String!) {
    franchiseSaleById(sale_id: $sale_id) {
      _id
      created_at
      items {
        charge_type
        downsell {
          reason
          type
          unit_id
        }
        implantation_value
        is_accumulative
        name
        total_value
        type
        unit_value
        quantity
        sale_id
        index
        billing_date_periods
        immediate_billing
        plan
      }
      owner
      type
      temporary_churn_period {
        start_date
        end_date
      }
      temporary_churn_reverted
      is_ambev_participation
      ambev_incentive {
        is_ambev_incentive
        ambev_incentive_months
        ambev_incentive_text
      }
    }
  }
`

export const SET_PRODUCT_BILLING_DATE_FRANCHISE = gql`
  mutation setFranchiseProductBillingDate($available_product_id: String!, $billing_date: DateTime!) {
    setFranchiseProductBillingDate(available_product_id: $available_product_id, billing_date: $billing_date) {
      message
      success
    }
  }
`
